<template>
  <div>
    <div style="display: flex; justify-content: space-between; align-items: center; padding: 10px;">

      <div style="display: flex; align-items: center;">
        <img src="/assets/img/logo.png" alt="K-Ads Logo" style="height: 70px; margin-right: 10px;">
       <a href="/" style="text-decoration: none;"> <h2 style="color: #2ecc71; margin: 0;">Tài Nguyên MMO</h2></a>
      </div>
      <div style="display: flex; gap: 20px;">
        <v-btn text variant="text" style="color: black;" href="/">Nạp rút</v-btn>
        <v-btn text variant="text" style="color: black;" href="/buy">Mua tài khoản</v-btn>
        <v-btn text variant="text" style="color: black;" href="/pending">Chờ thanh toán</v-btn>
        <v-btn text variant="text" style="color: black;" href="/paid">Chờ nhân viên xử lý</v-btn>
        <v-btn text variant="text" style="color: black;" href="/success">Đã xong</v-btn>
        <!-- <v-btn text variant="text" style="color: black;" href="/profile">Tài khoản</v-btn> -->
        <v-btn text variant="text" style="color: black;" @click="logout()">Đăng xuất</v-btn>
      </div>

    </div>
  </div>
  </template>
  
  <script>
  import auth from '@/services/auth';
  
  export default {
    methods: {
      async logout() {
       auth.removeToken();
       window.location.href = "/login"
      },
    },
  };
  
  </script>
  
  