<template>
  <div>
    <div style="display: flex; justify-content: space-between; align-items: center; padding: 10px;">

      <div style="display: flex; align-items: center;">
        <img src="/assets/img/logo.png" alt="K-Ads Logo" style="height: 40px; margin-right: 10px;">
        <h2 style="color: black; margin: 0;">Tài Nguyên MMO</h2>
      </div>
      <div style="display: flex; gap: 20px;">
        <v-btn text variant="text" style="color: black;" href="/admin/user">User</v-btn>
        <v-btn text variant="text" style="color: black;" href="/admin/pending">Pending Order</v-btn>
        <v-btn text variant="text" style="color: black;" href="/admin/success">Successful Order</v-btn>
      </div>

    </div>
  </div>
  </template>
  
  <script>
  export default {
  };
  </script>
  
  