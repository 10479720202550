<template>
  <div>
    <!-- Layout điều hướng của người dùng -->
    <NavigationLayout v-if="show"></NavigationLayout>
    <!-- Layout điều hướng của admin -->
    <AdminNavLayout v-if="!show"></AdminNavLayout>

    <v-content>
      <!-- Chứa router-view để render các component theo route -->
      <router-view></router-view>
    </v-content>
    <v-dialog v-model="dialogNoti" width="auto" min-width="200">
      <v-card>
        <v-card-text>
          <h3>{{ contentNoti }}</h3>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="green" @click="dialogNoti = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NavigationLayout from "./components/Layout/NavigationUser.vue";
import AdminNavLayout from "./components/Layout/NavigationAdmin.vue";
import auth from "./services/auth";
export default {
  name: "App",
  components: {
    NavigationLayout,
    AdminNavLayout
  },
  data() {
    return {
      dialogNoti: false,
      show: true,      // Biến kiểm soát layout
      message: '',     // Biến để lưu trữ thông báo từ SignalR
      isConnected: false // Trạng thái kết nối với SignalR
    };
  },
  watch: {
    $route(to) {
      // Thay đổi layout dựa trên đường dẫn
      if (to.path.startsWith("/admin")) {
        this.show = false;
      } else {
        this.show = true;
      }
    }
  },
  mounted() {
  // Kiểm tra nếu token tồn tại
  const socket = new WebSocket('wss://tainguyenmmo.vn:8088/api/NotiWS/Get?token=' + auth.token);

  socket.onopen = () => {
    console.log('Connected to the server via WebSocket');
  };

  // Sự kiện này sẽ được gọi khi nhận được một tin nhắn từ server qua WebSocket
  socket.onmessage = (event) => {  // Sử dụng arrow function
    let data = event.data;
    try {
      if (data != "OK") {
        console.log("Data: ", data);
        this.dialogNoti = true;        // Đảm bảo this vẫn trỏ tới component Vue
        this.contentNoti = data;
      }
    } catch (error) {
      console.log("Lỗi", error);
    }
  };

  // Sự kiện này được gọi khi có lỗi xảy ra trong quá trình giao tiếp WebSocket
  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  // Sự kiện này được gọi khi kết nối WebSocket bị đóng
  socket.onclose = () => {
    console.log('WebSocket connection closed');
  };
}

};
</script>

<style>
/* Styles của bạn */
</style>
