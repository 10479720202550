import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

// Router
import router from './router'

// Khởi tạo Vuetify
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

// Tạo ứng dụng Vue
const app = createApp(App)

// Thêm biến toàn cục
app.config.globalProperties.$server = 'https://tainguyenmmo.vn:8088';

// Sử dụng router và Vuetify
app.use(router)
app.use(vuetify)
// Mount ứng dụng
app.mount('#app')
