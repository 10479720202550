import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/services/auth'
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresUser: true } ,
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    meta: { requiresUser: true } ,
    component: () => import('../views/BuyView.vue')
  },
  {
    path: '/pending',
    name: 'pending',
    meta: { requiresUser: true } ,
    component: () => import('../views/PendingView.vue')
  },
  {
    path: '/paid',
    name: 'paid',
    meta: { requiresUser: true } ,
    component: () => import('../views/PaidView.vue')
  },
  {
    path: '/success',
    name: 'success',
    meta: { requiresUser: true } ,
    component: () => import('../views/SuccessView.vue')
  },
  {
    path: '/cancelled',
    name: 'cancelled',
    meta: { requiresUser: true } ,
    component: () => import('../views/CancelledView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { requiresAuth: true } ,
    component: () => import('../views/admin/PendingView.vue')
  },
  {
    path: '/admin/user',
    name: 'AdminUser',
    meta: { requiresAuth: true } ,
    component: () => import('../views/admin/UserView.vue')
  }
  ,
  {
    path: '/admin/user/:id',
    name: 'AdminUserDetail',
    meta: { requiresAuth: true } ,
    component: () => import('../views/admin/UserDetail.vue')
  },
  {
    path: '/admin/pending',
    name: 'AdminUserPending',
    meta: { requiresAuth: true } ,
    component: () => import('../views/admin/PendingView.vue')
  },
  {
    path: '/admin/success',
    name: 'AdminUserSuccess',
    meta: { requiresAuth: true } ,
    component: () => import('../views/admin/SuccessView.vue')
  },
  {
    path: '/admin/cancelled',
    name: 'AdminUserCancelled',
    meta: { requiresAuth: true } ,
    component: () => import('../views/admin/CancelledView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from, next) => {
  var check = await  auth.validateToken();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (check!="admin") {
      next({ name: 'login'});
    } else {
        next();
    }
  } 
  if (to.matched.some(record => record.meta.requiresUser)) {
    if (check=="admin"||check=="user") {
      next();
    } else {
     
      next({ name: 'login'});
    }
  }
  next();

});

export default router
